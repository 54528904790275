import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  // private _storage: Storage | null = null;

  constructor(private _storage: Storage) {
    this.init();
  }
  
  async init() {
    console.warn('StorageService.init()');
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);
    // this._storage = await this.storage.create();
    this._storage.create();
    
  }

  // Create and expose methods that users of this service can
  // call, for example:
  public async set(key: string, value: any) {
    return this._storage?.set(key, value);
  }

  public async get(key: string) {
    return this._storage?.get(key);
  }

  public async remove(key: string) {
    return this._storage?.remove(key);
  }

  public async clear() {
    return this._storage.clear()
  }
  
  public async keys() {
    return this._storage.keys()
  }
  
  public async length() {
    return this.length();
  }

  public async forEach(fn: (key, value, index) => {}) {
    return this._storage.forEach(fn);
  };
}