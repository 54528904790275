// import versionInfo from './version';
import { Capacitor } from "@capacitor/core";
import { DefaultPrivacyLevel } from "@datadog/browser-rum";

export default {
    versionInfo: {
        shortHash: null,
        longHash: null,
        dateStamp: Date.now(),
    },
    phone: '888-670-2630',
    welcomePhone: '615.645.1997',
    onboardingQuestionnaire: 'https://mycase.forthepeople.com/?idx=INTAKE_ID&ct=Automobile+Accident&lit=Personal+Injury&utm_source=sf&utm_campaign=rr-questionnaire&utm_medium=agent&utm_content=full',
    litifyApiBaseUrl: 'https://mm-matter-gtw-dev.azure-api.net/account/api',

    supportEmail: 'clientportal@forthepeople.com',
    litfyDateReformatPattern: 'MM/dd/yy',
    saveDelay: 2500,
    maxUploadFileSizeMB: Capacitor.getPlatform()=='android' ? 25 : 60,
    // maxUploadFileSizeMB: 60,
    initialTreatmentSurveyName: 'IT_Task',
    initialTreatmentProviderSurveyName: 'Initial_Treatment_Provider',
    schedulingInitialTreatmentSurveyName: 'Scheduling_Initial_Treatment',
    measureOneSurveyName: 'Measure_One_Task',
    measureOneFormSurveyName: 'Measure_One_Form',
    measureOneScriptUrl: 'https://api-stg.measureone.com/v3/js/m1-link-2021042000.js',

    consoleLogs: {
        mixpanel: false,
    },

    cameraPluginPrompt: false,

    saveJournalEntryToast: {
        colors: {
            // success: 'success',
            success: 'secondary',
            failure: 'danger',
        }
    },
    mixPanel: {
        apikey: "d92f76a57d0cdb25c0050fbf9fe043dc",
    },
    webRender: false,
    googlePlaces: {
        apikey: 'AIzaSyBFgfjAwLEoWoIWKGXO_JYy7nEynBQOXMI',
        textSearchApi: 'https://maps.googleapis.com/maps/api/place/textsearch/json',
        autocompleteApi: 'https://maps.googleapis.com/maps/api/place/autocomplete/json',
      },

    messages: {
        queryLimit: 15, // Infinite scroll has hard time detecting the first load if the limit is 10 or low
        shortInterval: 1*30*1000,
        longInterval: 2*60*1000,
    },

    extendSession: {
        idleSeconds: (5*60),
        timeoutSeconds: (1*60),
    },

  api: {
    baseUrl: 'OVERRIDE_ME_IN_ENVIRONMENT_TS',
    edwinBaseUrl: 'OVERRIDE_ME_IN_ENVIRONMENT_TS',
    edwinOnboardingTemplateId:  'OVERRIDE_ME_IN_ENVIRONMENT_TS',

    // authZeroUserApi:  'OVERRIDE_ME_IN_ENVIRONMENT_TS',
    mattersUri: 'matters',
    journalUri: 'journal',
    providersUri: 'providers',
    painLevelUri: 'pain-level',
    medicationsUri: 'medications',
    injuriesUri: 'injuries',
    pharmaciesUri: 'pharmacies',
    bodyPartsUri: 'bodyParts',
    documentsUri: 'documents',
    messagesUri: 'messages',
    pushNotificationsUri: 'pushNotifications',
    questionnaire: 'questionnaire',
  },

    authentication: {
        appId: '',
        appPassword: '',
    },

    errors: {
        required: 'Please complete this field.',
        validDate: 'TODO',
        journalForms: {

        }
    },
    reduceInitializations: false,
    sandbox: false,
    monitoring: {
        sentryDNS: 'OVERRIDE_ME_IN_ENVIRONMENT_TS',
        datadog: {
            applicationId: 'OVERRIDE_ME_IN_ENVIRONMENT_TS', // application ID is public
            clientToken: 'OVERRIDE_ME_IN_ENVIRONMENT_TS', // client token is public
            site: 'datadoghq.com',
            service: 'OVERRIDE_ME_IN_ENVIRONMENT_TS',
            env: 'OVERRIDE_ME_IN_ENVIRONMENT_TS',
            version: 'WILL_BE_SET_BY_CAPACITOR',
            allowFallbackToLocalStorage: true,  // this got iOS working, but with a messed up session replay
            sessionSampleRate: 100,
            sessionReplaySampleRate: 100,
            trackUserInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            // defaultPrivacyLevel: 'mask-user-input'  does not work from this file
            defaultPrivacyLevel: DefaultPrivacyLevel.MASK_USER_INPUT,
        }
    },
    devHacks: {
        datadog: {
            showEnvData: false,
        },
        initialTreatment: {
            gettingStartedFooter: false,
        }
    },
    scanbot: {
      nativeLicense: "E72phrjNIisz+OsJ3YllzOs2mUFtwM" +
        "nn3HgsZ9q02kaLf8L3giWYzq27YTzh" +
        "TnEUc9GPO51nqafQHlLJZqnbgsxUQF" +
        "d4CrGa9qqkqmR5Pq1SGL2xNaydYJJ7" +
        "dToHDfHaM9GktRL+SoDP/gEdVPwfHB" +
        "U4dkNdpt+TVSZTsHgcROv1kcQrHnk4" +
        "Abpr/xJQcnf9RQnvgHihyCIUJxfwNK" +
        "bx8zLVmj+i5LkwLodc/g3ps5gOATwq" +
        "11joJ+D7pOE5wcvNd32wI0BojUkxxX" +
        "f/FJrKyuNPWEeer8dWaalq1d4y/6UK" +
        "rpcYTZasRCpM+X5BQzo1CMC6H6yosY" +
        "fd3I3t5ikXWg==\nU2NhbmJvdFNESw" +
        "pjb20ubW0uY2xpZW50UG9ydGFsfGNv" +
        "bS5tb3JnYW4uQ2xpZW50LVBvcnRhbA" +
        "oxNzU1NTYxNTk5Cjc4CjM=\n",
      webLicense: "mkDi1AB/s26M/aNiS/YQMC9RSFGT4A" +
        "9fYkalQXt85kfzovrP0nI4uHw8UyRW" +
        "Jo9bMr4AkpDh0WozdBpjbNBWPVoKxx" +
        "N17tzNVQusPI82uCe6sF/ZE7oVCIZL" +
        "kajzEx0m6hWLXARFs07tgqdmRzPJwA" +
        "Im4NL6Q0K5ANVGIv+HvjhNCWRuWbZ0" +
        "vyMheUBs8qYptqmlq+3wFijmCKkKgb" +
        "CuLEhrbN7/T/1I4ELwTiM5yIMyua2n" +
        "8A+84Tj3pCQxdKAc71johPn9uFjuRB" +
        "WSQyw1s2QCgheVYqRNP/DYos1NOzyg" +
        "NrpFkcgAbMoezCF0CHpM4ZtaMyl7e+" +
        "WjLpbXPLJdWg==\nU2NhbmJvdFNESw" +
        "phcHAuZm9ydGhlcGVvcGxlLmNvbXxs" +
        "b2NhbGhvc3R8bW1jbGllbnRwb3J0YW" +
        "xtb2JpbGVkZXYub25yZW5kZXIuY29t" +
        "fG1tY2xpZW50cG9ydGFsbW9iaWxlYW" +
        "RtaW4ub25yZW5kZXIuY29tfG1tY2xp" +
        "ZW50cG9ydGFsbW9iaWxldWF0Lm9ucm" +
        "VuZGVyLmNvbQoxNzU1NTYxNTk5Cjc4" +
        "Cjg=\n"
    }
};
