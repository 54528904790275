import { Injectable } from '@angular/core';
import { Intake, IntakesService } from './intakes.service';
import { CaseData, Matter, MatterService, MatterStatusOptions } from './matter.service';
import { switchMap, combineLatest, map, BehaviorSubject, Observable, tap, shareReplay, filter, merge, iif, of, Subject, firstValueFrom, skip, distinctUntilChanged, catchError, startWith } from 'rxjs';
import { QuestionnaireService } from './questionnaire.service';
import { StorageService } from './storage.service';
import { CaseStatusMappingsService } from './case-status-mappings.service';
import { AppSchema, AppSchemaService } from '../directives/app-schema/app-schema.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';



export interface ClientCaseCard extends Intake {
  matter?: Matter,
  status: string,
  caseProgress: number,
  caseProgressBar: number,
  completedOnboarding?: boolean,
  completedInitialTreatment?: boolean,  //ABOVE: completedInitialTreatment
  initialTreatmentFeature: boolean,
  appSchema?: AppSchema,
}

export interface ClientCase extends ClientCaseCard {
// move just about everything from above down to here
// after refactoring to load additional case and matter info only after selecting a case
}


interface InitialTreatment {
  id: string;
  next_appointment_date: string
  initial_treatment_completed: boolean,
  salesforce_intake_id: string,
}




// export type Case = Matter|IntakeRecord;

@Injectable({
  providedIn: 'root'
})
export class CaseService {
  private caseTypesExcludedFromSurveys = ['Ohio Train Derailment'];

  private readonly initialTreatmentSurveyName = environment.initialTreatmentSurveyName;
  private readonly schedulingInitialTreatmentSurveyName = environment.schedulingInitialTreatmentSurveyName;
  private readonly initialTreatmentProviderSurveyName = environment.initialTreatmentProviderSurveyName;

  public casesLoading$ = new BehaviorSubject<boolean>(false);
  private casesRefresh$ = new BehaviorSubject<null>(null); //not needed with matter and intake forceRefresh






  /**
   * All cases:  Intake with Matter attached to it (if a matter exists for the case)
   */
  public cases$: Observable<ClientCaseCard[]> = this.casesRefresh$.pipe(
    switchMap(() => combineLatest([
      this.intakeService.intakes$,
      this.matterService.mattersInfo$.pipe(
        map( (mattersInfo:CaseData) => mattersInfo.matters ),
      ),
    ])),

    map( ([intakes, matters]:[Intake[], Matter[]]) => ({
      intakes: intakes,
      matters : matters,
    })),
    map( ({intakes, matters}) => intakes.map(
      (intake:Intake) => {
        const matter = matters.find( matter => matter.intakeId===intake.intakeId);
        return matter ?
          {
            ...intake,
            matter,
          }
          : intake;
      }
    ) ),
  )
  .pipe(
    switchMap( (cases: ClientCaseCard[]) => iif(
      () => cases.length === 0,  //This IIF is the bug-fix for MMCP-2287
      of([]), //MMCP-2287: return an Observable with an empty array - combineLatest() will never emit on an empty array!!!
      combineLatest(
        cases.map( (c: ClientCaseCard, i: number) =>
          this.csmService.getCaseStatusMapping(c).pipe(
            map( (status:string) => ({...cases[i], status}) ) //append status mapping to each original case
          )
        )
      ),
    )),

    map((cases: ClientCaseCard[]) =>
      cases.map((cc: ClientCaseCard) => ({
        ...cc,
        caseProgress: +this.translateCaseStatusToProgress(cc),
        caseProgressBar: +this.translateCaseStatusToProgressBar(cc),
      }),
    )),

    map((cases:ClientCaseCard[]) => cases.map((cc: ClientCaseCard) =>
      ({
        ...cc,
        caseStatus: cc.matter ?
          (cc.matter.caseType === 'Ohio Train Derailment' ?
            this.getOhioTrainDerailmentMatterStatus(cc.caseProgress) :
            this.getMatterStatus(cc.caseProgress)
          ) : cc.caseStatus==='pending' ? 'active' : cc.caseStatus, //MMCP-2563
      }),
    )),



    switchMap((cases:ClientCaseCard[]) =>
      combineLatest(
        cases.map( (cc: ClientCaseCard) => this.appSchemaService.getTemplate(cc).pipe(
          map( (schema:AppSchema) => ({
            ...cc,
            appSchema: schema,
          })),
          catchError( (err) => of(cc) ),
        ) )
      )
    ),


  ).pipe(  //add this since long pipes break - so break up into 2 pipes
    map((cases:ClientCaseCard[]) => cases.sort((a,b) => Date.parse(b.createdDate) - Date.parse(a.createdDate))),
    map((cases:ClientCaseCard[]) => cases.sort((a,b) => (b.caseStatus === 'active' || b.caseStatus === 'pending') ? 1 : -1)),
    shareReplay(1),
  );



  private setCaseId$ = new BehaviorSubject<string>('');
  private setMatterId$ = new BehaviorSubject<string>('');

  public selectedCaseInit$: Observable<ClientCaseCard> = this.cases$.pipe(
    distinctUntilChanged( (prev,curr) => JSON.stringify(prev)===JSON.stringify(curr)),
    switchMap( (cases:ClientCaseCard[]) =>
      merge(

        this.setCaseId$.pipe( //Most Likely tapped in ClientCaseSelection
          filter( (id:string) => id!==''),
          map( (intakeId:string) => cases.find( (c:ClientCaseCard)=>c.intakeId===intakeId) ),
        ),

        this.setMatterId$.pipe( //Most Likely tapped in NotificationCenter
          filter( (id:string) => id!==''),
          map(  (matterId:string) => cases.filter( (c:ClientCaseCard)=>c.matter ).find( (c:ClientCaseCard)=>c.matter.id===matterId ) ),
        )
      )
    ),

    switchMap( (c:ClientCaseCard) => iif(
      () => c === undefined,
      of(null).pipe(skip(1)),
      of(c).pipe(
        tap( (c:ClientCaseCard) => this.storageService.set('selectedCaseId', c.intakeId) ),
        filter( (c:ClientCaseCard) => c!==undefined )
      ).pipe(
      )
    )),

    startWith(<ClientCaseCard>{}), //Add this so that even on first subscribe, the NEXT case is always take(2) away!
    shareReplay(1),
  );

  public selectedCase$ = this.selectedCaseInit$.pipe(
    filter((cc) => JSON.stringify(cc)!==JSON.stringify({})),
  );


  public selectedCaseAppSchemaTemplate$:Observable<AppSchema> =
    this.selectedCase$.pipe(
      switchMap( (cc:ClientCaseCard) => this.appSchemaService.getTemplate(cc) )
    )


  constructor(
    private http: HttpClient,
    private intakeService: IntakesService,
    private matterService: MatterService,
    private questionnaireService: QuestionnaireService,
    private storageService: StorageService,
    private csmService: CaseStatusMappingsService,
    private appSchemaService: AppSchemaService,
  ) {
    this.init();
  }


/* ******************************************************************** */
  public readonly caseStatuses = [
    ...Object.values(MatterStatusOptions).map((s: string) => s),
  ];

  public translateCaseStatusToProgress(cc: ClientCaseCard): number {
    return this.caseStatuses.indexOf(cc.status);
  }

  public translateCaseStatusToProgressBar(cc: ClientCaseCard): number {
    const status = this.translateCaseStatusToProgress(cc);
    return (status < 5) ? status : 5;
  }
  /* ******************************************************************** */



  async init() {
    const caseId = await this.storageService.get('selectedCaseId');
    console.warn('CaseService.init() caseId:', caseId);
    if(caseId) {
      this.setCaseId$.next(caseId);
    }
  }

  public async setCurrentCaseById(id:string) {
    if (!id) {
      await this.storageService.remove('selectedCaseId');
    }

    this.setCaseId$.next(id);
  }

  public async setCurrentCaseByMatterId(id:string) {
    this.setMatterId$.next(id);
  }


  getMatterStatus(matterProgress: number): string{
    if (matterProgress === 0) {
      return 'pending';
    } else if (matterProgress > 0 && matterProgress < 5) {
      return 'active';
    } else if (matterProgress >= 5 && matterProgress <= 7) {
      return 'inactive';
    }
  }

  getOhioTrainDerailmentMatterStatus(matterProgress: number): string{
    if (matterProgress <= 4) {
      return 'active';
    } else {
      return 'inactive';
    }
  }


  public async refreshCases() {
    return new Promise( (resolve, reject) => {
      setTimeout(
        async () => {
          this.matterService.forceRefresh();
          this.intakeService.forceRefresh();
          resolve(firstValueFrom(this.cases$.pipe(skip(1))));
        },
        500
      )
    })
  }
}