/* eslint-disable no-console */
import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, from, combineLatest } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { AuthService, isTokenExpired, AuthState } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  debug = true;

  constructor(
    private authService: AuthService,
  ) {
    console.info('********** HttpRequestInterceptor is loaded **********');
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const newReqHandle = !!['login', 'logout', 'us-east-2-documents', 'registration/status', 'us-west-2', 'mmmobileattachments', 'us-east-1-prod-mmdocuments.s3.amazonaws.com']
      .filter(str => req.url.includes(str)).length ?
      next.handle(req)
      :
      from(this.authService.isAuthenticated()).pipe(
        switchMap( (isAuthenticated) =>
          combineLatest([
            from(this.authService.getIdToken()),
            from(this.authService.getAccessToken()),
          ])
          .pipe(
            map( ([jwt, token]:  [any, string]) => ({jwt, token}) ),
            // tap( ({jwt, token}) => console.log('        ...TOKEN:', token, jwt)),
            // tap( (token:string) => console.log('...TOKEN epired?:', isTokenExpired(token)) ),
            switchMap(({jwt, token}) => (!token) ?
              next.handle(req).pipe(
                tap(() => console.log('NO PARAMS!!!!!!!!!!!')),
              )
              :
              next.handle(
                req.clone({
                  headers: req.headers
                    .set(
                      // we should be able to use just Bearer per Zach and Matt
                      'Authorization', `${ environment.useBasicToken ? 'Basic' : 'Bearer' } ${ token }`,
                    )
                    .set(
                      'PortalVersion', '3.0'
                    )
                }),
              ),
            ),
          )
        )
      );

    // don't reset session inactivity status if user is logged out
    // or if request is coming from message service polling
    // /authentication/login
    const logInRoute = /\/authentication\/login\//g;
    const pollingRoute = /\/messages\/(.*)mmispolling=true/g;
    const isLogin = req.url.match(logInRoute);
    const isPolling = req.url.match(pollingRoute);

    return newReqHandle;
  }
}
