import { IonicAuthOptions } from '@ionic-enterprise/auth';
import { ExtendedAuthOptions } from 'src/app/services/auth.service';
import { Capacitor } from '@capacitor/core';
import common from './common';

const isAndroid:boolean = Capacitor.getPlatform()=='android';


export const environment = {
  ...common,

  useBasicToken: false, //REMOVE ME after we're done with BasicToken

  api: {
    ...common.api,
    baseUrl: 'https://mm-sf-gateway-api-prod2.azurewebsites.net/portal',
    edwinBaseUrl: 'https://edwin-api-prod.onrender.com/api',
    samuelBaseUrl: 'https://samuel-sse.api.mx2.law',
    edwinOnboardingTemplateId: '',
  },
  portalEdition: 'prod',
  production: true,
  monitoring: {
    sentryDNS: 'https://ec8fc719b87a4d3987325e6dc8e809a6@o4504849340760064.ingest.sentry.io/4505483676680192',
    datadog: {
      ...common.monitoring.datadog,
      applicationId: isAndroid?'95366c28-fde3-47a6-854b-a77834079f83':'e85ace8b-a742-4e5e-a4ed-6caf4686ac18',
      clientToken: isAndroid?'pubfee60b72bb4262cd011343926d697573':'pub5c42207ada400e1c94c6b2f6773a857d',
      service: `mmclientmobile-${isAndroid?'android':'ios'}`,
      env: 'prod',
    }
  },
};


export const auth0WebOptions: IonicAuthOptions = {
  authConfig: 'auth0',
  platform: 'web',
  clientID: '3q2ijjp5R72aF43XwUJVnhkhNcNRwTm4',
  discoveryUrl: 'https://mm-login.us.auth0.com/.well-known/openid-configuration',
  redirectUri: 'http://localhost:8100/login',
  scope: 'openid offline_access email picture profile',
  audience: 'https://mm-login.us.auth0.com/api/v2/',
  logoutUrl: 'http://localhost:8100/logout',
  iosWebView: 'private',
  implicitLogin​: 'CURRENT', //'POPUP'
  webAuthFlow: 'PKCE',
}

export const auth0NativeOptions: IonicAuthOptions = {
  authConfig: 'auth0',
  platform: 'capacitor',
  clientID: '9FDP9pSAcENibalt9LjtM1TgUnkGBKPL',
  discoveryUrl: 'https://mm-login.us.auth0.com/.well-known/openid-configuration',
  redirectUri: 'com.mm.clientportal:///login',
  scope: 'openid offline_access email picture profile',
  audience: 'https://mm-login.us.auth0.com/api/v2/',
  logoutUrl: 'com.mm.clientportal:///logout',
  iosWebView: 'private'
}
