import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';

import { Platform } from '@ionic/angular';
import { AuthResult, IonicAuth, IonicAuthOptions } from '@ionic-enterprise/auth';
// import { azureNativeIonicAuthOptions as nativeIonicAuthOptions, azureWebIonicAuthOptions as webIonicAuthOptions } from 'src/environments/environment';
import { auth0NativeOptions as nativeIonicAuthOptions, auth0WebOptions as webIonicAuthOptions } from 'src/environments/environment';
import { Observable, BehaviorSubject, tap, EMPTY, firstValueFrom, of, merge, Subject, lastValueFrom, from } from 'rxjs';
import { catchError, filter, map, shareReplay, switchMap, take } from 'rxjs/operators';
import jwt_decode from 'jwt-decode';

import { environment } from 'src/environments/environment';
import { MixPanelService } from './mix-panel.service';
import { LoginsResponseMessagesService } from 'src/app/services/logins-response-messages.service';
import { Capacitor } from '@capacitor/core';
import { Router } from '@angular/router';
import { createDelay } from './util';

const { api } = environment;

export const isTokenExpired = (token: string) => {
  // console.log("***isTokenExpired function***");

  let decoded: { exp: string };
  try {
    decoded = jwt_decode(token);
    //  throw("asdf");
  } catch (e) {
    console.error('ERROR DECODING TOKEN - RETURNING true FOR EXPIRED', e);
    return false;
  }
  const now: number = Date.now();
  return +decoded?.exp < +`${now}`.substr(0, `${now}`.length - 3);
};

export const debounceFunction = (fn: Function, ms = 300) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};
const debouncedLog = debounceFunction((...args) => console.log(...args), 50);



export interface JWT {
  sub: string;
  aad_id: string;
  aud: string;
  matter_id: string;
  account_id: string;
  // salesforceId: string;
  exp: number;
}

interface LoginResponse {
  error?: any;
  token: string;
}

interface LogoutResponse {
  error?: any;
}

export interface AuthState {
  jwt: JWT | null;
  token: string;
}

export interface RegistrationValidationResponse {
  isValid: boolean;
  hasBeenUsed: boolean;
  hasExpired: boolean;
  notFound: boolean;
}

export interface RegistrationStatus {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  registrationId: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  registrationStatus: RegistrationStatusTypes;
}

enum RegistrationStatusTypes {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Invited = 'Invited',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Completed = 'Completed',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Expired = 'Expired',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  NotFound = 'NotFound',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  NetworkError = 'NetworkError',
}

export interface ExtendedAuthOptions extends IonicAuthOptions {
  registerDiscoveryUrl: string;
  changePasswordDiscoveryUrl: string;
  switchUrl: string;
  forgotEmailDiscoveryUrl: string;
  forgotPasswordDiscoveryUrl: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService extends IonicAuth {
  /**
   * *********************************************************************************
   * MEMBERS *************************************************************************
   * *********************************************************************************
   */
  private initialized = false;

  private authConnectJwtSubject: Subject<AuthState> = new Subject();
  private authConnectJwt$ = this.authConnectJwtSubject.asObservable().pipe(
    // skip(1),
    // tap((authState: AuthState) => console.log('JWT in authConnectJwt$ =====>', JSON.stringify(authState), authState)),
  );

  public currentState$: Observable<AuthState | null> = this.authConnectJwt$.pipe(
    shareReplay(1),
    // tap((authState: AuthState) => debouncedLog('currentState$ - AuthState:', JSON.stringify(authState))),
  );

  private authenticationChange: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public authenticationChange$: Observable<boolean> = this.authenticationChange.asObservable();

          //TODO:  RENAME justSignedIn$
  public justSignedIn$ = from(this.isAuthenticated()).pipe(
    switchMap( () => this.currentState$ ),
    filter((authState: AuthState) => authState.jwt !== undefined),
    // map((authState: AuthState) => !!authState.jwt),
    // take(1), //auto-unsubscribe
  );

          //TODO: isSignedIn$
  public isSignedIn$ = from(this.isAuthenticated()).pipe(
    switchMap( () => this.currentState$ ),
    // filter((authState: AuthState) => authState.jwt !== undefined),
    map((authState: AuthState) => !!authState.jwt),
    tap((authState: boolean) => console.log('isSignedIn$:', authState)),
    // take(1), //auto-unsubscribe
  );

  /**
   * *********************************************************************************
   * METHODS *************************************************************************
   * *********************************************************************************
   */
  private static getConfiguration(platform: Platform): IonicAuthOptions {  //ExtendedAuthOptions {
    return platform.is('hybrid') ? nativeIonicAuthOptions : webIonicAuthOptions;
  }

  public get config() {
    return AuthService.getConfiguration(this.platform);
  }

  constructor(
    private platform: Platform,
    public mixPanelService: MixPanelService,
    private ngZone: NgZone,
    private http: HttpClient,
    private loginMsgSvc: LoginsResponseMessagesService,
    private router: Router
  ) {
    super(AuthService.getConfiguration(platform));

    this.isAuthenticated().then((authenticated) => {
      this.onAuthChange(authenticated);
    });
  }

  private async onAuthChange(isAuthenticated: boolean): Promise<void> {
    let [jwt, token] = [undefined, undefined];
    console.log('onAuthChange: BEFORE this.getIdToken(), this.getAccessToken()');
    if (isAuthenticated) {
      try {
        [jwt, token] = await Promise.all([this.getIdToken(), this.getAccessToken()]);
      } catch(err: any) {
        console.error("onAuthChange ERROR:", err);
        const newErr = new Error();
        newErr.name = 'Registration Completed (not logged in)'
        newErr.message = 'simulated AADB2C90088 error';
        this.loginMsgSvc.sendMessage(newErr);
        return;
      }
    }
    console.log('onAuthChange: AFTER this.getIdToken(), this.getAccessToken()');

    this.ngZone.run(async () => {
      this.authenticationChange.next(isAuthenticated);
      this.authConnectJwtSubject.next(jwt ? <AuthState>{ jwt, token } : <AuthState>{});
      // console.log('++++++++++++++++++++++++++ JWT this.getIdToken(), this.getAccessToken():', jwt, environment.portalEdition==='admin' ? token : 'NOT_ADMIN__TOKEN_SUPPRESSED');
      console.log('############## isAuthenticated:', isAuthenticated);
    });
  }

  public async onLoginSuccess(): Promise<void> {
    console.log('onLoginSuccess()');
    this.onAuthChange(true);
  }

  public async onLogout(): Promise<void> {
    // or with require() syntax:
    // const mixpanel = require('mixpanel-browser');

    // Enabling the debug mode flag is useful during implementation,
    // but it's recommended you remove it for production

    console.log('onLogout()');
    this.onAuthChange(false);
  }

  public async login(email?: string) {
    // REMOVE ALL ONCE Azure-AD B2C IS GONE
    /*
    if (email) {
      this.additionalLoginParameters({
        login_hint: email,
        ui: 'pi'
      });
    } else {
      this.additionalLoginParameters({ui: 'pi'});
    }
    */
    this.additionalLoginParameters({connection: 'email'});
    console.warn('CONFIG:', this.config);
    return super.login();
  }

  public async logout(): Promise<void> {
    console.warn("BEFORE LOGOUT")
    await super.logout();
    // this.authConnectJwtSubject.next(<AuthState{});
    // this.authConnectJwtSubject.complete();
    if(Capacitor.getPlatform()==='web') await this.router.navigateByUrl('/');
    // await createDelay(1000);
  }


  /* REMOVE ALL ONCE Azure-AD B2C IS GONE
  public async register(): Promise<void> {
    this.additionalLoginParameters({ui: 'pi'});
    // return super.login(AuthService.getConfiguration(this.platform).registerDiscoveryUrl);
    return super.login();
  }

  public async changePassword() {
    const origRedirectUri = AuthService.getConfiguration(this.platform).redirectUri;
    AuthService.getConfiguration(this.platform).redirectUri = 'com.mm.clientportal://settings';
    this.additionalLoginParameters({ui: 'pi'});
    const loginPromise = super.login(); //AuthService.getConfiguration(this.platform).changePasswordDiscoveryUrl);
    await loginPromise;
    console.log('changePassword:  AFTER super.login');
    AuthService.getConfiguration(this.platform).redirectUri = origRedirectUri;

    return loginPromise;
  }

  public async forgotEmail(): Promise<void> {
    this.additionalLoginParameters({ui: 'pi'});
    // return super.login(AuthService.getConfiguration(this.platform).forgotEmailDiscoveryUrl);
    return super.login();
  }

  public async forgotPassword(): Promise<void> {
    this.additionalLoginParameters({ui: 'pi'});
    // return super.login(AuthService.getConfiguration(this.platform).forgotPasswordDiscoveryUrl);
    return super.login();
  }

  public async logoutReq() {
    return await firstValueFrom(this.http.get(AuthService.getConfiguration(this.platform).logoutUrl));
  }
  */
}
