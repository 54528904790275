import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, delay, map, Observable, of, switchMap, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Account, AccountService } from './account.service';
import { formatISO, subDays } from 'date-fns';

interface IntakeResponse {
  totalSize: number,
  done: boolean,
  records: IntakeRecord[],
}

interface IntakeRecord {
  attributes: any,
  Name: string, //Reference Number on Case/Intake
  Intake_Name__c: string,
  Id: string,
  CreatedDate: string,
  Injured_Party__c: string,
  Case_Type__c?: string, // this would map to 'caseType' field in related Matter
  caseType?: string, // this would map to 'caseType' field in related Matter
  Next_Appointment_Date_Initial__c?: string, // to figure out the default selection of treatment
  Facility_Name__c?: string,
  Next_Appointment_Address_Initial__c?: string,
  Next_Appt_Initial_Provider_Phone__c?: string,
  Next_Appointment_Information_Initial__c?: string,
  State_of_incident__c?: string,
  Status__c: string,
  Client_Preferences__c?: string,
}

export interface Intake {
  id?: string,
  referenceNumber: string,
  caseDisplayName: string,
  caseJourneyStatus: string,
  createdDate: string,
  caseStatus: string,
  state?: string,
  intakeId: string,
  surveyTemplateId?: number|string,
  caseType?: string,
}


interface InitialTreatment {
  id: string;
  next_appointment_date: string
  initial_treatment_completed: boolean,
  salesforce_intake_id: string,
}



@Injectable({
  providedIn: 'root'
})
export class IntakesService {
  private intakeRefreshSubject$ = new BehaviorSubject<null>(null);

  public intakesMock$: Observable<Intake[]> = of([{
    referenceNumber: 'a0000123548',
    caseDisplayName: 'blah',
    caseJourneyStatus: 'active',
    createdDate: 'today',
    caseStatus: 'pending',
    intakeId: '1234',
    caseType: 'AA',
    state: 'FL',
  }]).pipe(
    delay(1500)
  )

  public intakes$: Observable<Intake[]> = this.intakeRefreshSubject$.pipe(
    switchMap( () => this.accountService.account$),
    map((account:Account) => this.getQueryForIntakes(account)),
    switchMap((query: string) => this.http.post<IntakeResponse>(`${environment.api.baseUrl}/data`, query)),
    tap((intake:IntakeResponse) => console.log("IntakesService intakes$ (AFTER REQUEST):", intake)),

    map( (intakes:IntakeResponse) => intakes.records.map( record => ({
        ...record,
        caseType: record.Case_Type__c,
      })
    )),

    map( (intakes:IntakeRecord[]) => intakes.map(intake => ({
      referenceNumber: intake.Name.split('-')[1],
      caseDisplayName: intake.Intake_Name__c,
      caseJourneyStatus: intake.Status__c,
      createdDate: intake.CreatedDate,
      caseStatus: 'pending',
      intakeId: intake.Id,
      caseType: intake.caseType,
      state: intake.State_of_incident__c,
      // hasMatter: intake.hasMatter,
    }) ) ),

  ).pipe(
    tap( (cases: Intake[]) => console.log("IntakesService.intakes$ (FINAL)", cases)),
  );





  constructor(
    private accountService: AccountService,
    private http: HttpClient
  ) { }



  private getQueryForIntakes(account:Account) {
    const thirtyDaysAgo = formatISO(subDays(new Date(), 30));

    return `?q=SELECT Name, Intake_Name__c, Id, CreatedDate, Injured_Party__c, Case_Type__c, State_of_incident__c, Status__c
    FROM Intake__c
    WHERE Client__c = '${account.id}'
    AND (
      (
        Case_Type__c IN ('Automobile Accident', 'Ohio Train Derailment', 'Hair Relaxer')
        AND Status__c IN ('Retainer Received', 'Converted', 'Referred Out')
        AND Retainer_Received__c != null
      )
      OR
      (
        Case_Type__c IN ('Slip and Fall', 'Trip and Fall', 'General Injury', 'Animal Incident', 'Negligent Security', 'Salon', 'Food Poisoning', 'Bed Bugs', 'NY Worksite Injury', 'General Liability')
        AND (
          (
            Status__c IN ('Retainer Received', 'Converted', 'Referred Out')
            AND Retainer_Received__c != null
          )
          OR (Status__c = 'Lead' AND CreatedDate > ${encodeURIComponent(thirtyDaysAgo)})
        )
      )
    )`;
  }

  

  public doesIntakeHaveAMatter(intakeId: string): Observable<boolean> {
    const query = `?q=select count() from litify_pm__Matter__c where Intake__c = '${intakeId}'`;

    return this.http.post<IntakeResponse>(`${environment.api.baseUrl}/data`, query).pipe(
      map(intake => intake.totalSize > 0)
    );
  }

  public getTreatmentsInfoUsingIntakeId(intakeId: string): Observable<IntakeRecord[]> {
    const query = `?q=select Next_Appointment_Date_Initial__c, Facility_Name__c, Next_Appointment_Address_Initial__c, Next_Appt_Initial_Provider_Phone__c, Next_Appointment_Information_Initial__c, Client_Preferences__c from client_care__c where Intake__c = '${intakeId}'`;
    return this.http.post<IntakeResponse>(`${environment.api.baseUrl}/data`, query).pipe(
      tap((intake) => console.log("TreatmentDetails SSQL by Intake:", intake)),
      map(intake => intake.records)
    );
  }


  public forceRefresh() {
    this.intakeRefreshSubject$.next(null);
  }
}
