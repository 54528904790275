import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, of, retry, shareReplay } from 'rxjs';
import { environment } from 'src/environments/environment';


export interface PainLevel {
  label: string;
}

export interface BodyPart {
  label: string;
  value: string;
  cssClassName?: string;
}


@Injectable({
  providedIn: 'root'
})
export class MiscStaticDataService {
  private painLevelsList: PainLevel[] = [
    {label: 'Mild Pain'},
    {label: 'Mild Pain'},
    {label: 'Mild Pain'},
    {label: 'Moderate Pain'},
    {label: 'Moderate Pain'},
    {label: 'Severe Pain'},
    {label: 'Severe Pain'},
    {label: 'Very Severe Pain'},
    {label: 'Very Severe Pain'},
    {label: 'Worst Pain Possible'},
  ];

  public painLevels$: Observable<PainLevel[]> = of(this.painLevelsList);

  public bodyParts$: Observable<BodyPart[]> = this.http.get<BodyPart[]>(`${environment.api.baseUrl}/bodyParts`)
  .pipe(
    retry(3),
    shareReplay(1),
  );


  public static readonly states = [
    { value: 'AL', label: 'AL'},
    { value: 'AK', label: 'AK'},
    { value: 'AZ', label: 'AZ'},
    { value: 'AR', label: 'AR'},
    { value: 'CA', label: 'CA'},
    { value: 'CO', label: 'CO'},
    { value: 'CT', label: 'CT'},
    { value: 'DE', label: 'DE'},
    { value: 'DC', label: 'DC'},
    { value: 'FL', label: 'FL'},
    { value: 'GA', label: 'GA'},
    { value: 'HI', label: 'HI'},
    { value: 'ID', label: 'ID'},
    { value: 'IL', label: 'IL'},
    { value: 'IN', label: 'IN'},
    { value: 'IA', label: 'IA'},
    { value: 'KS', label: 'KS'},
    { value: 'KY', label: 'KY'},
    { value: 'LA', label: 'LA'},
    { value: 'ME', label: 'ME'},
    { value: 'MD', label: 'MD'},
    { value: 'MA', label: 'MA'},
    { value: 'MI', label: 'MI'},
    { value: 'MN', label: 'MN'},
    { value: 'MS', label: 'MS'},
    { value: 'MO', label: 'MO'},
    { value: 'MT', label: 'MT'},
    { value: 'NE', label: 'NE'},
    { value: 'NV', label: 'NV'},
    { value: 'NH', label: 'NH'},
    { value: 'NJ', label: 'NJ'},
    { value: 'NM', label: 'NM'},
    { value: 'NY', label: 'NY'},
    { value: 'NC', label: 'NC'},
    { value: 'ND', label: 'ND'},
    { value: 'OH', label: 'OH'},
    { value: 'OK', label: 'OK'},
    { value: 'OR', label: 'OR'},
    { value: 'PA', label: 'PA'},
    { value: 'RI', label: 'RI'},
    { value: 'SC', label: 'SC'},
    { value: 'SD', label: 'SD'},
    { value: 'TN', label: 'TN'},
    { value: 'TX', label: 'TX'},
    { value: 'UT', label: 'UT'},
    { value: 'VT', label: 'VT'},
    { value: 'VA', label: 'VA'},
    { value: 'WA', label: 'WA'},
    { value: 'WV', label: 'WV'},
    { value: 'WI', label: 'WI'},
    { value: 'WY', label: 'WY' }
  ];

  public static readonly humanBodyPartsList = [
    {
      value: "Abdomen",
      label: "Abdomen",
      cssClassName: 'human-abdomen',
    },
    {
      value: "Arm/Hand - L",
      label: "Arm/Hand - Left",
      cssClassName: 'human-left-arm',
    },
    {
      value: "Arm/Hand - R",
      label: "Arm/Hand - Right",
      cssClassName: 'human-right-arm',
    },
    {
      value: "Back - Lower",
      label: "Back - Lower",
      cssClassName: 'human-back-lower',
    },
    {
      value: "Back - Middle",
      label: "Back - Middle",
      cssClassName: 'human-back-middle',
    },
    {
      value: "Back - Upper",
      label: "Back - Upper",
      cssClassName: 'human-back-upper',
    },
    {
      value: "Chest",
      label: "Chest",
      cssClassName: 'human-chest',
    },
    {
      value: "Head",
      label: "Head",
      cssClassName: 'human-head',
    },
    {
      value: "Hip/Thigh - Left",
      label: "Hip/Thigh - Left",
      cssClassName: 'human-left-thigh',
    },
    {
      value: "Hip/Thigh - Right",
      label: "Hip/Thigh - Right",
      cssClassName: 'human-right-thigh',
    },
    {
      value: "Knee - Left",
      label: "Knee - Left",
      cssClassName: 'human-left-knee',
    },
    {
      value: "Knee - Right",
      label: "Knee - Right",
      cssClassName: 'human-right-knee',
    },
    {
      value: "Leg/Foot - Left",
      label: "Leg/Foot - Left",
      cssClassName: 'human-left-leg',
    },
    {
      value: "Leg/Foot - Right",
      label: "Leg/Foot - Right",
      cssClassName: 'human-right-leg',
    },
    {
      value: "Neck",
      label: "Neck",
      cssClassName: 'human-neck',
    },
    {
      value: "Pelvis",
      label: "Pelvis",
      cssClassName: 'human-pelvis',
    },
    {
      value: "Ribs - Left",
      label: "Ribs - Left",
      cssClassName: 'human-left-ribs',
    },
    {
      value: "Ribs - Right",
      label: "Ribs - Right",
      cssClassName: 'human-right-ribs',
    },
    {
      value: "Shoulder - Left",
      label: "Shoulder - Left",
      cssClassName: 'human-left-shoulder',
    },
    {
      value: "Shoulder - Right",
      label: "Shoulder - Right",
      cssClassName: 'human-right-shoulder',
    },
  ]



  constructor(
    private http: HttpClient,
  ) { }

  get painLevels(): PainLevel[] {
    return this.painLevelsList;
  }

}
