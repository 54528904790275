import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import {
  AlertButton,
  ModalController,
  Platform,
} from '@ionic/angular';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  filter,
  firstValueFrom,
  lastValueFrom,
  map,
  Observable,
  of,
  skip,
  Subject,
  Subscription,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import {
  NotificationCaseStatus,
  NotificationMessage,
  NotificationOnboardingReminder,
  NotificationPainLevel,
  NotificationTreatmentScheduled,
  NotificationCallRescheduled,
} from 'src/app/services/notification/notification-factory';
import { INotification } from 'src/app/services/notification.service.interface';
import { environment } from 'src/environments/environment';
import { Account, AccountService } from './services/account.service';
import { AlertService } from './services/alert.service';
import { AuthService } from './services/auth.service';
import { DeeplinksService } from './services/deeplinks.service';
import { InAppNotificationToastService as InAppNotificationService } from './services/in-app-notification.service';
import { CaseData, Matter, MatterService } from './services/matter.service';
import { MessageService } from './services/message.service';
import { MixPanelService } from './services/mix-panel.service';
import { NetworkService } from './services/network.service';
import { NotificationSettings, NotificationSettingsService } from './services/notification-settings.service';
import { NotificationService } from './services/notification.service';
import { NotificationsCenterService } from './services/notifications-center.service';
import { PainLevelReminderService } from './services/pain-level-reminder/pain-level-reminder.service';
import { SplashScreenService } from './services/splash-screen.service';
import { ToastService } from './services/toast.service';
import { ScheduledMaintenanceService } from './services/scheduled-maintenance.service';
import { register } from 'swiper/element/bundle';
import { CaseService } from './services/case.service';
import { DatadogService } from './services/datadog.service';
import { UpgradeRequired, UpgradeService } from 'src/app/services/upgrade.service';
import { UpgradePage } from './pages/upgrade/upgrade.page';
import { UserInfoCachingService } from './services/user-info-caching.service';
import { ScanbotService } from './services/scanbot.service';
import { CallSchedulingService, ScheduledCall } from './services/call-scheduling.service';


register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  public webRender = environment.webRender;
  private routerEventsSub: Subscription;
  routeSub: Subscription | null = null;
  initializeLocalNotificationSchedulerSub: Subscription = null;
  painLevelDaysChangedSub: Subscription = null;
  isWeb: boolean = Capacitor.getPlatform() === 'web';

  
  notificationsActionSub: Subscription | null = null;
  notificationsActionTaken$ = combineLatest([
    this.notificationService.notifications$.pipe(
      filter((notification: INotification) => notification.action),
      tap(() => {
        this.deeplinksService.bypassDeepLinks = true;
      }),
    ),
  ]).pipe(
    map(([notification]: [INotification]) => notification),
    tap((notification: INotification) => console.log('notificationsActions$ - combineLatest value:', notification)),
  );

  notificationsInAppSub: Subscription | null = null;
  notificationsInApp$ = combineLatest([
    this.notificationService.notifications$.pipe(filter((notification: INotification) => !notification.action)),
  ]).pipe(
    map(([notification]: [INotification]) => notification),
    tap((notification: INotification) => console.log('notificationsInApp$ - combineLatest value:', notification)),
  );

  private matters$: Observable<Matter[]> = this.matterService.mattersInfo$.pipe(
    map((caseData: CaseData) => <Matter[]>caseData.matters),
  );
  private accountId$ = this.accountSvc.account$.pipe(map((account: Account) => <string>account.id));

  networkDisconnectedSub: Subscription = null;
  networkConnectedSub: Subscription = null;

  private unsubscribe$: Subject<null> = new Subject<null>();

  /**
   *
   * METHODS
   *
   */
  constructor(
    public platform: Platform,
    public mixPanelService: MixPanelService,
    private router: Router,
    private zone: NgZone,
    private authSvc: AuthService,
    private accountSvc: AccountService,
    private matterService: MatterService,
    private caseService: CaseService,
    private messageService: MessageService,
    private notificationService: NotificationService,
    private inappNotificationService: InAppNotificationService,
    private notificationsCenterService: NotificationsCenterService,
    private painLevelReminderService: PainLevelReminderService,
    private deeplinksService: DeeplinksService,
    private notificationSettingsService: NotificationSettingsService,
    private networkService: NetworkService,
    private toastService: ToastService,
    private modalController: ModalController,
    private alertService: AlertService,
    private scheduledMaintenanceService: ScheduledMaintenanceService,
    private splashScreen: SplashScreenService,
    private datadogService: DatadogService,
    private upgradeService: UpgradeService,
    private userInfoCaching: UserInfoCachingService,
    private scanbotService: ScanbotService,
    private callSchedulingService: CallSchedulingService,
  ) {
    this.routeSub = this.deeplinksService.route$.subscribe((route: string) => {
      console.log('AppComponent route$.subscribe() route:', route);
      this.zone.run(() => this.router.navigateByUrl(route));
    });

    this.deeplinksService.initialize();

    if(!this.isWeb) {
      App.getInfo().then((info) => {
        this.mixPanelService.setSuperProperty({
          'appVersion': info.version,
        });
      });
      this.authSvc.isAuthenticated().then((isAuthenticated) => {
        if(!isAuthenticated) this.deeplinksService.pushRoute('/splash-screen', true);
      })
    } else {
      this.mixPanelService.setSuperProperty({
        'appVersion': 'web',
      });
    }
  }

  ngOnDestroy(): void {
    console.warn('AppComponent NG-ON-DESTROY')
    if (this.routerEventsSub) {
      this.routerEventsSub.unsubscribe();
    }
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
    if (this.painLevelDaysChangedSub) {
      this.painLevelDaysChangedSub.unsubscribe();
    }
    if (this.initializeLocalNotificationSchedulerSub) {
      this.initializeLocalNotificationSchedulerSub.unsubscribe();
    }
    if (this.networkConnectedSub) {
      this.networkConnectedSub.unsubscribe();
    }
    if (this.networkDisconnectedSub) {
      this.networkDisconnectedSub.unsubscribe();
    }

    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  ngOnInit() {
    this.mixPanelService.init();
    this.notificationService.init();
    if(!environment.reduceInitializations) {
      this.datadogService.init();
      // this.sentryService.init();
      this.initializeNotifications();
      this.initializeLocalNotificationScheduler();
      this.initializeNotificationCenter();
      this.initializeMarkAsRead();
      this.initializeNetworkService();
      this.scheduledMaintenanceService.handleScheduledMaintenance();
    }
    // this.messageService.startPolling();
    this.initPlatformReady();
    this.initializeUpgradeCheck();
    if (!this.isWeb) {
      this.scanbotService.initScanbotSdk();
    }
  }

  public exit(): void {
    App.exitApp();
  };

  public async logout() {
    console.log(`LOGOUT`);
    this.mixPanelService.track('CLICKED_MORE_ITEM', { itemName: 'Logout' });
    this.mixPanelService.track('LOGOUT', { method: 'User Prompted' });
    await this.authSvc.logout();
    this.messageService.cancelPolling();
  }

  initializeUpgradeCheck() {
    if(!this.isWeb){
      this.upgradeService.upgradeRequired$.subscribe(
        (updateRequired: UpgradeRequired) => {
          if(updateRequired.outageEnabled || updateRequired.required) {
            this.mixPanelService.track('FORCED_UPGRADE');
            this.openUpgradeCheckModal();
          } 
        }
      );
    }
  }

  async openUpgradeCheckModal() {
    const modal = await this.modalController.create({
      component: UpgradePage,
      canDismiss: false,
    });
    return modal.present();
  }

  /**
   * Subscribe separately to disconnected$ and connected$,
   * since connected$ has an initial skip(1) which
   * disconnected$ does not have.
   */
  initializeNetworkService() {
    this.networkDisconnectedSub = this.networkService.disconnected$.subscribe((disconnected: boolean) => {
      const buttons = [<AlertButton>{text:"Dismiss", handler: () => this.modalController.dismiss(close)}];

      this.alertService.createAlert('Network Disconnected', buttons)
      this.matterService.forceRefresh();
    });

    this.networkConnectedSub = this.networkService.connected$.subscribe((disconnected: boolean) => {
      this.toastService.showSuccessToast('Network connected');
      console.log('Network connected: this.matterService.forceRefresh()');
      this.zone.run(() => {
        // Bring event back inside Angular's zone
        this.matterService.forceRefresh();
      });
    });
  }

  initializeMarkAsRead() {
    this.routerEventsSub = combineLatest([
      this.matterService.mattersInfo$,
      this.router.events.pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd)),
    ])
      .pipe(debounceTime(5000))
      .subscribe(([_, routeData]: [CaseData, NavigationEnd]) => {
        const { url } = routeData;

        if (url === '/tabs/case') {
          this.notificationsCenterService.markCaseStatusNotificationsRead();
        }

        if (url === '/tabs/messages') {
          this.notificationsCenterService.markMessageNotificationsRead();
        }

        if (url === '/journal/pain-level/add') {
          this.notificationsCenterService.markPainLevelNotificationsRead();
        }
      });
  }

  initPlatformReady() {
    this.platform.ready().then(() => {
      this.platform.pause.subscribe(() => {
        console.log('****Platform.ready():  App PAUSED****');
        this.networkService.removeNetworkListener();
      });
      this.platform.resume.subscribe(() => {
        console.log('****Platform.ready():  App RESUMED****');
        this.networkService.addNetworkListener();
        this.zone.run(async () => {
          this.matterService.forceRefresh();
        });
      });
    });
  }

  initializeNotificationCenter() {
    // firstValueFrom(this.matterService.mattersInfo$).then(this.notificationsCenterService.init);
    this.notificationsCenterService.init();
  }

  initializeLocalNotificationScheduler() {
    this.initializeLocalNotificationSchedulerSub = combineLatest([
      this.notificationSettingsService.toggleStates$,
      this.matters$,
      this.accountId$,
    ])
      .pipe(debounceTime(10000))
      .subscribe((combinedValues: [NotificationSettings, Matter[], string]) => {
        this.painLevelReminderService.init(combinedValues);
      });
  }



  initializeNotifications() {
    /**
     * ACTION TAKEN ON A NOTIFICATION
     * - from an OS notification,
     * - from an in-app 'toast'-style notification,
     * - or from tapping an item in the Notification Center
     *    //TODO:  Currently separate NotificationCenterService code is handling this
     */
    this.notificationsActionTaken$.pipe(
      takeUntil(this.unsubscribe$),
    )
    .subscribe(async (notification: INotification) => {
      console.log('AppComponent.notificationsActions$.subscribe:', notification, JSON.stringify(notification));
      this.splashScreen.hide();

      this.pushNotificationRespondToAction(notification);
      // this.zone.run(() => {
      //   this.respondToAction(notification);
      // })
    });



    /**
     * NOTIFICATION RECEIVED FROM FCM WHILE APP IS IN FOREGROUND
     */
    this.notificationsInApp$.pipe(
      takeUntil(this.unsubscribe$),
    )
    .subscribe(async (notification: INotification) => {
      console.log('AppComponent.notificationsInApp$.subscribe:', notification);
      this.pushNotificationinAppBehavior(notification);
    });
  }





  pushNotificationRespondToAction(notification:INotification) {
    console.log('AppComponent.respondToAction():', notification);
    if (
      notification instanceof NotificationMessage ||
      notification instanceof NotificationCaseStatus ||
      notification instanceof NotificationOnboardingReminder ||
      notification instanceof NotificationPainLevel ||
      notification instanceof NotificationTreatmentScheduled ||
      notification instanceof NotificationCallRescheduled
    ) {
      // await this.matterService.setCaseById(notification.data.matterId);
      console.warn('AppComponent notification (selectedCase):', notification);

      this.zone.run(async () => {
        if (notification instanceof NotificationCaseStatus) {
          console.warn('PUSH NOTIFICATION ACTION for:', notification);

          console.warn('AppComponent.pushNotificationRespondToAction() BEFORE refreshCases():',  notification);
          await this.caseService.refreshCases();
          console.warn('AppComponent.pushNotificationRespondToAction() AFTER refreshCases():',  notification);

          // await firstValueFrom(
          //   this.caseService.casesUpdated$.pipe(skip(1))
          // );
          // console.warn('AFTER AWAIT casesUpdated$:', notification);
        }

        console.warn("PUSH NOTIFICATION ACTION for matterID:", notification.data.matterId);
        this.caseService.setCurrentCaseByMatterId(notification.data.matterId);

        await firstValueFrom(
          this.caseService.selectedCase$.pipe(
            filter( (val) => val.matter?.id === notification.data.matterId)
          )
        );
        console.warn("AFTER await selectedCase$:", notification.data.matterId);

        if (notification instanceof NotificationPainLevel) {
          this.notificationsCenterService.markNotificationRead(notification);
        }

        this.deeplinksService.pushRoute(notification.route);
        console.log('AppComponent.notificationsActions$ - after routerService.push()', notification.route);
        // this.bypassDeepLinksOnNotificationActionLaunched = false;

        if (notification instanceof NotificationCallRescheduled) {
          this.callSchedulingService.upcomingCall$.pipe(take(1)).subscribe((call: ScheduledCall) => {
            if (call) {
              this.callSchedulingService.openMeetingModal(call);
            } 
          });
        }

        this.deeplinksService.bypassDeepLinks = false;
      });
    }
  }



  pushNotificationinAppBehavior(notification:INotification) {
    if (
      notification instanceof NotificationMessage ||
      notification instanceof NotificationCaseStatus ||
      notification instanceof NotificationOnboardingReminder ||
      notification instanceof NotificationPainLevel ||
      notification instanceof NotificationTreatmentScheduled ||
      notification instanceof NotificationCallRescheduled
    ) {
      if (notification instanceof NotificationCaseStatus || notification instanceof NotificationTreatmentScheduled) {
        this.zone.run(async () => {
          // this.matterService.forceRefresh();
          console.warn('AppComponent.pushNotificationinAppBehavior() BEFORE refreshCases():',  notification);
          this.caseService.refreshCases();
          console.warn('AppComponent.pushNotificationinAppBehavior() AFTER refreshCases():',  notification);
        });

        // await this.matterService.getLatestMatterInfo(); //TODO:  Why doesn't template always update unless twice?
        // console.log('MatterService.getLatestMatterInfo() COMPLETE  --  (AppComponent.notificationsInApp$.subscribe)');
      }

      if (notification instanceof NotificationPainLevel) {
        this.notificationsCenterService.markNotificationSent(notification);
      }
      
      if (notification instanceof NotificationCallRescheduled) {
        this.callSchedulingService.upcomingCall$.pipe(take(1)).subscribe((call: ScheduledCall) => {
          if (call) {
            this.callSchedulingService.openMeetingModal(call);
          } 
        });
      }

      this.inappNotificationService.show(notification);
    }

  }

}
/*
adb shell am broadcast \ -n com.mm.clientportal/com.google.firebase.iid.FirebaseInstanceIdReceiver \ -a "com.google.android.c2dm.intent.RECEIVE" \ --es "title" "title" \ --es "body" "body" \ --es "deeplink" "{{deeplink}}"
adb shell am broadcast \ -n com.mm.clientportal/com.google.firebase.iid.FirebaseInstanceIdReceiver \ -a "com.google.android.c2dm.intent.RECEIVE" \ --es "title" "title" \ --es "body" "body" \ --es "deeplink" "app.forthepeople.com://"
*/