import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { skip } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoginsResponseMessagesService {
  private messageSubject$:BehaviorSubject<Error|null> = new BehaviorSubject<Error|null>(null);
  public messages$:Observable<Error> = this.messageSubject$.asObservable().pipe(
    skip(1),
  )

  constructor() { }

  sendMessage(err: Error) {
    this.messageSubject$.next(err);
  }
}
