import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, from, iif, map, mergeMap, Observable, of, retry, shareReplay, Subject, switchMap, tap } from 'rxjs';
import { AuthService, AuthState, JWT } from './auth.service';

import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { MixPanelService } from './mix-panel.service';
import { StorageService } from './storage.service';
import { indicate } from './util/rxjs/operators';
import { DatadogService } from './datadog.service';



export interface Account {
  id: string;
  source: string;
  clientStreet: string;
  clientName: string;
  clientFirstName: string;
  clientLastName: string;
  clientPhone: string;
  clientPostalCode: string;
  clientState: string;
  clientCity: string;
  clientEmail: string;
}

export interface AuthZeroUserResponse {
  email_verified: boolean;
}



@Injectable({
  providedIn: 'root'
})
export class AccountService {
                                              ///api/users/email_verification
  private userApi = `${environment.api.edwinBaseUrl}/users`

  public accountLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public account$: Observable<Account> = this.authSvc.currentState$?.pipe(
    // filter((authState: AuthState) => !!authState.jwt),
    map((authState: AuthState) => <JWT>authState.jwt),
    switchMap((jwt: JWT) => this.http.get<Account[]>(`${environment.api.baseUrl}/accounts/${jwt?.account_id}`).pipe(
      // delay(3000), // add delay to prove loading indicator works on Case Selection Screen
      indicate(this.accountLoading$),
      map( (accounts: Account[]) => accounts.length ? accounts[0] : null ),
      filter( (account: Account) => !!account ),

      tap((account: Account) => {
        this.mixPanelService.setAlias(
          account.clientEmail, account.clientEmail
        );
        this.mixPanelService.setSuperProperty({
          'email': account.clientEmail,
          'accountId': account.id,
        });

        this.datadogService.setUser(account);

        if(this.backFromSignIn){
          this.mixPanelService.setPeople(account.clientEmail,{ 
            'name': account.clientName,
            'email': account.clientEmail,
            'registrationDate': new Date()
            });
          this.mixPanelService.track('ACCOUNT_CREATED');
          console.info("%c MIXPANEL TRACKER:  ACCOUNT_CREATED", 'background: #222; color: green')
        }
          this.mixPanelService.setPeople(account.clientEmail,{ 
            'name': account.clientName,
            'email': account.clientEmail,
            'env': environment.portalEdition,
            'lastLogin': new Date()
            });
          this.mixPanelService.track('LOGIN');
      }),

    )),
    tap((account: Account) => console.log('AccountService: account$ subsribed - pre-hot:', account)),
    tap((account: Account) => console.warn('%c Account: ',  'background: blue; color: yellow', account)),
    shareReplay(1)
  );

  private _fromSignIn = false;


  constructor(
    private http: HttpClient,
    private mixPanelService: MixPanelService,
    private authSvc: AuthService,
    private storageService: StorageService,
    private datadogService: DatadogService,
  ) { }

  private get backFromSignIn() {
    const retVal = this._fromSignIn;
    this._fromSignIn = false;
    return retVal;
  }

  public queueBackFromSignin() {
    this._fromSignIn = true;
  }



/**
 * All Email Verifiaciton members and methods
 */

  private EMAIL_VERIFIED_KEY = 'verifiedEmail';

  public isEmailVerifiedHTTP(accountID: string): Observable<boolean> {
    return <Observable<boolean>>this.http.get<AuthZeroUserResponse>(`${this.userApi}/email_verification`)
    .pipe(
      retry(4),
      indicate(this.isEmailVerifiedLoading$),
      map( (result:AuthZeroUserResponse) => !!result.email_verified ),
      tap( (emailVerified: boolean) => console.log('AccountService: isEmailVerifiedHTTP():', emailVerified)),
    )
  }

  private async isEmailVerifiedStorage(accountID: string): Promise<boolean> {
    const verifiedAccounts:string[] = await <Promise<string[]>>this.storageService.get(this.EMAIL_VERIFIED_KEY) ?? []
    const emailVerified =  verifiedAccounts.includes(accountID)
    console.log('AccountService: isEmailVerifiedStorage():', emailVerified, accountID, verifiedAccounts);
    return emailVerified;
  }

  private async setEmailVerified(accountID: string): Promise<boolean> {
    const verifiedAccounts:string[] = await <Promise<string[]>>this.storageService.get(this.EMAIL_VERIFIED_KEY) ?? []
    const verifiedAccountsSet = new Set(verifiedAccounts);
    await verifiedAccountsSet.add(accountID);
    return this.storageService.set(this.EMAIL_VERIFIED_KEY, [...verifiedAccountsSet])
    .then(
      () => {
        console.log('AccountService: setEmailVerified() .then():', accountID);
        return true;
      }
    );
  }

  private isEmailVerified(accountID:string): Observable<boolean> {
    return from(this.isEmailVerifiedStorage(accountID)).pipe(
      // tap( (emailVerified: boolean) => console.log('AccountService: isEmailVerifiedStorage():', emailVerified)),
      mergeMap( (emailVerified: boolean) => iif(
        () => emailVerified,
        of(true),
        this.isEmailVerifiedHTTP(accountID).pipe(
          // tap( (emailVerified: boolean) => console.log('AccountService: isEmailVerifiedHTTP():', emailVerified)),
          mergeMap( (emailVerified: boolean) => emailVerified ? from(this.setEmailVerified(accountID)) : of(false)),
          // map( (emailVerified: boolean) => emailVerified )
        )
      )),
      tap( (emailVerified: boolean) => console.log('AccountService: FINAL isEmailVerified():', emailVerified)),
    )
  }

  public isEmailVerifiedLoading$ = new Subject<boolean>();
  public isEmailVerified$:Observable<boolean> = <Observable<boolean>>this.account$?.pipe(
    switchMap( (account: Account) => this.isEmailVerified(account.id)),
    // map( (emailVerified: boolean) => true), //TODO: REMOVE
    // map( (emailVerified: boolean) => false), //TODO: REMOVE
    tap( (emailVerified: boolean) => console.log('AccountService: isEmailVerified$:', emailVerified)),
  )

  resendEmailVerification() {
    return this.http.post(`${this.userApi}/resend_email_verification_email`, {})
  }

}

/* ACCOUNT RESPONSE:
[
    {
        "source": "Account",
        "clientEmail": "jannellstandberry@gmail.com",
        "clientCity": "Orlando",
        "clientState": "FL",
        "clientPostalCode": "40202",
        "clientPhone": "8139651950",
        "clientName": "Cynthia Fake",
        "clientStreet": "123 Main Street",
        "id": "0013c00001oIjvcAAC"
    }
]
*/