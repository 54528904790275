import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

import {EventSourcePolyfill} from 'ng-event-source';


import { AuthService } from './auth.service';
import { firstValueFrom, tap } from 'rxjs';
const { samuelBaseUrl } = environment.api


@Injectable({
  providedIn: 'root'
})
export class UserInfoCachingService {
  eventSource = null;
  cancelTimeout = null;

  constructor(
    private zone: NgZone,
    private http: HttpClient,
    private authService: AuthService,
  ) { }

   async getHeaders() {
    await firstValueFrom(this.authService.justSignedIn$);
    const token = await this.authService.isAuthenticated().then( auth => this.authService.getAccessToken() );

    return {
      "Content-Type": "text/event-stream",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    }    
  }


  private getMessageJSON(data): SseData {
    const messageText = data.data;
    let message = <SseData>{};
    messageText.split('\n').forEach(v => {
      const kv = v.split(': ');
      if(kv[0] !== '') message[kv[0]] = kv[1];
    })
    return message;
  }


  public async waitForCaching() {
    const headers = await this.getHeaders();

    if(!this.eventSource) {
      this.eventSource = new EventSourcePolyfill(`${samuelBaseUrl}/subscribe`, {
        headers,
      });
    }

    return new Promise( async (resolve, reject) => {
      let startTime;
      let endTime;

      if(!this.cancelTimeout) {
        this.cancelTimeout = setTimeout(
          () => {
            console.warn('eventSource - %cTIMEOUT:', 'color:green', new Date(), '\n\nno response after connection');
            this.close();
            reject('no_response_from_caching');
          },
          12_000
        )
      }

      this.eventSource.onopen = async (a) => {
        startTime = new Date();
        console.log('eventSource - %conOpen', 'color:green', startTime);
        const response = await firstValueFrom(this.http.get(`${environment.api.edwinBaseUrl}/users/latest_user_info`));
      };

      this.eventSource.onmessage = (data => {
        endTime = new Date();
        console.log('eventSource - %conMessage', 'color:green', endTime, '- TIME SINCE onOpen:', (endTime-startTime)/1000, '\n\n', data.data);
        this.zone.run(() => {
          const message:SseData = this.getMessageJSON(data);
  
          if(message.event === 'caching_tasks_completed') {
            clearTimeout(this.cancelTimeout);
            this.close();
            resolve(message);
          }
        });
      });

      this.eventSource.onerror = (e) => {
        console.error('eventSource - %conError', 'color:green', new Date(), e);
        this.close();
        reject(e);
      }
  
    })
  }


  public close() {
    console.log('eventSource - %cclose', 'color:green');
    if (this.eventSource) {
      this.eventSource.close();
      this.eventSource=null;
      this.cancelTimeout=null;
    }
  }

}


interface SseData {
  data: string;
  event: string;
  id: string;
  retry: string;
}