import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { PreviewAnyFile } from '@awesome-cordova-plugins/preview-any-file/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import player from 'lottie-web';
import { LottieCacheModule, LottieModule } from 'ngx-lottie';
import { quickPageAnimation } from 'src/app/animations';
import { AuthInterceptor } from 'src/app/interceptors/auth-interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AlertModule } from './components/alert/alert.module';
import { UpgradePageModule } from './pages/upgrade/upgrade.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      scrollPadding: false,
      scrollAssist: false,
      navAnimation: quickPageAnimation,
    }),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AlertModule,
    LottieModule.forRoot({player: () => player}),
    LottieCacheModule.forRoot(),
    UpgradePageModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    PreviewAnyFile,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  // init tracer
  constructor() {}
}
