import { Injectable } from '@angular/core';
import { LocalNotifications, ScheduleEvery } from '@capacitor/local-notifications';
import { firstValueFrom, lastValueFrom, map } from 'rxjs';
import { Matter } from 'src/app/services/matter.service';
import { NotificationsCenterService } from 'src/app/services/notifications-center.service';
import { environment } from 'src/environments/environment';
import { PainLevelReminderStorage } from './pain-level-reminder-storage';
import { PainLevelReminderUtil } from './pain-level-reminder-util.service';
import { NotificationReminder, PostNotification } from './pain-level-reminder.service';

export const { portalEdition } = environment;

@Injectable({
  providedIn: 'root',
})
export class PainLevelReminderScheduler {

  constructor(
    private notificationsCenterService: NotificationsCenterService,
    private painLevelReminderStorage: PainLevelReminderStorage,
    private painLevelReminderUtil: PainLevelReminderUtil,
  ) {}

  public scheduleRecurringReminder(matter: Matter, accountId: string) {
    if (matter.caseType === 'Ohio Train Derailment') return;

    const notificationBody = this.generatePostNotification(accountId, matter);
    let incrementedId = +new Date();

    firstValueFrom(
      this.notificationsCenterService.mattersNotifications$.pipe(
        map((mattersNotifications) => mattersNotifications?.scheduled?.filter(
            (notification) => notification.scheduleDate?.toString() === notificationBody.scheduleDate?.toString(),
          ) || []
        ),
        map(async (scheduled) => {
          const posted = scheduled.length ?
            scheduled[0] :
            await lastValueFrom(this.notificationsCenterService.postNotification(matter, notificationBody));

          const painLevelReminder = this.generateReminder(
            notificationBody, matter, incrementedId, posted, notificationBody.scheduleDate
          );
          await this.painLevelReminderStorage.setRecurringWednesdayPainReminder(matter, painLevelReminder);

          return LocalNotifications.schedule({
              notifications: [
                {
                  ...painLevelReminder,
                  id: Math.trunc(Math.abs((incrementedId + 10)/60000000)), // divide by 60000000 to provide id within allowed range
                  extra: painLevelReminder,
                  schedule: {
                    at: notificationBody.scheduleDate,
                    every: <ScheduleEvery>'week',
                  }
                },
              ],
            })
            .then(() => (
              { notificationBody, painLevelReminder, id: incrementedId }
            ));

        }),
      ),
    )
      .catch(console.error);
  }

  public getRecurringWednesdayPainReminder(matter: Matter) {
    return this.painLevelReminderStorage.getRecurringWednesdayPainReminder(matter)
      .then((reminder) => reminder?.wednesday);
  }

  public async clearPainLevelReminders(matter: Matter) {
    return this.painLevelReminderStorage.clearPainLevelReminders(matter);
  }

  private generateReminder = (
    notificationBody: PostNotification,
    matter: Matter,
    id: number,
    posted,
    scheduleDate: Date,
  ): NotificationReminder =>
    (
      {
        title: notificationBody.title,
        body: notificationBody.body,
        matterId: matter.id,
        localNotificationId: id,
        pushNotificationId: posted.id,
        type: 'painLevel',
        id,
        scheduleDate,
      } as NotificationReminder
    );

  private generatePostNotification = (accountId: string, matter: Matter): PostNotification =>
    (
      {
        accountId,
        title: 'Morgan & Morgan',
        body: `How are you feeling today? Log your pain level!`,
        status: 'Scheduled',
        matterId: matter.id,
        data: `{ "body": "How are you feeling today? Log your pain level!", "title": "Morgan & Morgan", "type": "painLevel" }`,
        inputSource: 'Client Portal',
        topic: `${ portalEdition }_painLevel_${ accountId }`,
        scheduleDate: this.painLevelReminderUtil.nextOccurringDay(3), /* 3 = wednesday */
      } as PostNotification
    );
}
