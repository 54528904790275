import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable } from 'rxjs';
import { filter, map, shareReplay, startWith, switchMap, take, tap } from 'rxjs/operators';
import { BodyPart, MiscStaticDataService } from './misc-static-data.service';
import { indicate } from './util/rxjs/operators';
import { environment } from 'src/environments/environment';
import { Injury } from '../pages/journal/injury/injury.service';
import { Medication } from 'src/app/pages/journal/medication/medication.service';
import { format } from 'date-fns';
import { CaseService, ClientCaseCard } from './case.service';

export interface Journal {
  id: string;
  source: string;
  createdByName: string;
  detail: string;
  entry: string;
  entryDate: string;
  createdByDisplay?: string;
  providerName?: string;
  createdDate?: string;
  onsetDate?: string;
  painLevel?: string;  //TODO - can we remove this or combine types?  ()
}

export interface Treatment {
  providerId: string;
  appointmentDate: string;
  injuryIds: string;
  treatmentType: string;
  description: string;
  matterId: string;
  createdByName?: string;
  id?: string;
  recordsConfirmed?: string;
}

export interface TreatmentType {
  label: string;
  value: string;
}
export interface TreatmentPostResponse {
  action: string;
  id: string;
}

export interface PainLevel {
  painDate: string;
  painLevel?: string;
  bodyPart?: string;
  notes?: string;
  matterId?: string;
  createdByName?: string;
}

export interface PainLevelSummary {
  painDate?: string;
  painLevel?: string;
  bodyPart?: string;
  notes?: string;
  createdBy?: string;
}

const today: () => string = () => format(new Date(), "yyyy-MM-dd");

@Injectable({
  providedIn: 'root'
})
export class JournalService {
  public journalLoading$ = new BehaviorSubject<boolean>(false);
  private updateJournal: BehaviorSubject<null> = new BehaviorSubject(null);
  public journals$: Observable<Journal[]> = this.updateJournal.pipe(
    switchMap(() =>
      this.caseService.selectedCase$
        .pipe(
          switchMap((cd: ClientCaseCard) => this.getJournalEntriesByMatterID(cd.matter.id).pipe(
            startWith([]),
            tap((journal: Journal[]) => console.log('JournalResponse:', journal)),
            map((journals: Journal[]) => journals.map(
              (journal: Journal) => ({
                ...journal,
                entry: journal.source === 'Pain_Level__c' ? `${journal.entry} - ${this.painDataSvc.painLevels[Number(journal.entry) - 1]?.label}` : journal.entry,
                painLevel: journal.entry,
                createdByDisplay: journal.createdByName === 'Mobile Portal Integration User' ? 'Me' : journal.createdByName,
              })
            )
            ),
          ).pipe(
            indicate(this.journalLoading$),
          )),
          shareReplay(1),
        )
    )
  );

  public todaysPainLoading$ = new BehaviorSubject<boolean>(false);
  public todaysPainLevel$ = <Observable<{ id?: string; label?: string }>>combineLatest([this.caseService.selectedCase$, this.updateJournal])
    .pipe(
      map(([caseData, v]: [ClientCaseCard, null]) => caseData),
      filter((cd: ClientCaseCard) => !!cd.matter),
      switchMap((caseData: ClientCaseCard) => this.getPainLevelsById(caseData.matter.id).pipe(
        indicate(this.todaysPainLoading$),
      )),
      map((painLevels: PainLevel[]) => painLevels.filter(
        (pl: PainLevel) => pl.painDate === today()
      )),
      tap((painLevels: PainLevel[]) => console.log('todaysPaneLevel$ (filtered)', painLevels)),
      map((painLevels: PainLevel[]) => painLevels.length > 0 ? painLevels[painLevels.length - 1].painLevel : ''),

      map((pl: string) => pl === '' ? { id: '-1' } : ({ ...this.painDataSvc.painLevels[Number(pl) - 1], id: pl })),
      startWith({ id: '', label: null }),
    );

  public selectedHumanBodyParts: BodyPart[] = [];
  public painLevelEntrySelectedDate: string = '';

  constructor(
    private caseService: CaseService,
    private http: HttpClient,
    private painDataSvc: MiscStaticDataService,
  ) { }

  public refreshJournal(): void {
    this.updateJournal.next(null);
  }

  getMedicationById(id: string) {
    return <Observable<Medication[]>>this.http.get(
      environment.api.baseUrl + "/medications/" + id,)
  }

  getPainLevelsById(id: string): Observable<PainLevel[]> {
    return <Observable<PainLevel[]>>this.http.get(
      environment.api.baseUrl + "/painlevels/" + id,
    )
  }

  getInjuryById(id: string) {
    return <Observable<Injury[]>>this.http.get(
      environment.api.baseUrl + "/injuries/" + id,
    )
  }

  getTreatmentById(id: string) {
    return <Observable<Treatment[]>>this.http.get(
      environment.api.baseUrl + `/treatments/${id}?sort=-appointmentDate`
    );
  }

  getTreatedInjuryById(treatmentId: string) {
    return <Observable<Injury[]>>this.http.get(
      environment.api.baseUrl + "/treatedInjuries/" + treatmentId,
    );
  }

  public getTreatmentTypes(): Observable<TreatmentType[]> {
    return <Observable<TreatmentType[]>>this.http.get(
      environment.api.baseUrl + "/treatmentTypes");

  }

  public saveTreatment(form: any): Observable<TreatmentPostResponse> {
    return this.caseService.selectedCase$.pipe(
      take(1),
      switchMap((matters: ClientCaseCard) => {
        const treatment = {
          ...form,
          matterId: matters.matter.id,
        };

        return <Observable<TreatmentPostResponse>>this.http
          .post(environment.api.baseUrl + '/treatments/', treatment);

      }),
      tap(() => this.refreshJournal()), // force journal$ to update after adding value (http.post is complete)
    );
  }

  async savePainLevel(_painLevel: PainLevel) {
    const httpReq =
      this.caseService.selectedCase$.pipe(
        take(1),
        switchMap((matters: ClientCaseCard) => {
          const painLevel: PainLevel = {
            ..._painLevel,
            matterId: matters.matter.id,
          };
          return this.http.post(`${environment.api.baseUrl}/painlevels`, painLevel)
        }),
        tap(() => () => this.refreshJournal()), // force journal$ to update after adding value (http.post is complete)
      );

    return firstValueFrom(httpReq);
  }

  public getJournalEntriesByMatterID(id: string): Observable<Journal[]> {
    return this.http.get<Journal[]>(`${environment.api.baseUrl}/journals/${id}?sort=-createdDate`);
  }
}
